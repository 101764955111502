<template>
  <div class="mt-70-px">
    <navbar title=""></navbar>
    <div v-if="angemeldeterMitarbeiter" class="container-fluid tile-container">
      <div class="row mt-5">
        <div class="col-xl-4 col-lg-12">
          <div class="row pt-1">
            <div class="col-lg-12">
              <div class="tile-categorie-frame">
                <div class="tile-categorie">
                  <div class="header">
                    <span>Produkte</span>
                  </div>
                  <div class="body">
                    <div class="row">
                      <div class="col-12">
                        <div class="tile-frame h-1 left-aligned">
                          <router-link :to="{ name: 'produkt-liste' }">
                            <div class="tile bg-light-blue inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-box"
                                  transform="shrink-2"
                                />
                              </div>
                              <div class="title">Produkte</div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div>
                    <div class="row">
                      <div class="col-12">
                        <div class="tile-frame h-1 left-aligned">
                          <router-link :to="{ name: 'produktgruppen-liste' }">
                            <div class="tile bg-light-blue inverted">
                              <div class="icon-container fa-container">
                                <font-awesome-icon
                                  icon="fa-duotone fa-boxes-stacked"
                                  transform="shrink-2 left-1"
                                />
                              </div>
                              <div class="title">Produktgruppen</div>
                            </div>
                          </router-link>
                        </div>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
</template>
	
	<script>
import page from "@/mixins/Page";
import Navbar from "@/components/Navbar";
import store from "@/store";

import Mitarbeiter from "@/models/Mitarbeiter";

export default {
  name: "Startseite",
  components: {
    Navbar,
  },
  mixins: [page],
  store,
  metaInfo() {
    return {
      titleTemplate: () => {},
    };
  },
  data() {
    return {
      name: "",
    };
  },
  computed: {},
  created() {
    this.name = Mitarbeiter.fullName(this.angemeldeterMitarbeiter);
  },
  mounted() {},
};
</script>
	
	<style lang="scss">
</style>
	